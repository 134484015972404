import React from 'react'
import SectionTitle from '../SectionTitle'
import ClubsGallery from './ClubsGallery'

const Clubs = ({
    sectionTitle,
        sectionTitleDesc,
        sectiontitleBtnTxt,
        sectiontitleBtnUrl,
        isBlackBg,


        hideBtn,
        gallery
}) => {
    return (
        <section id="clubs" className={isBlackBg? "black_bg" : "light_bg"}>
            <SectionTitle 
                isBlackBg={isBlackBg}
                title={sectionTitle}
                description={sectionTitleDesc}
                url={sectiontitleBtnUrl
                }
                urlBtnTxt={sectiontitleBtnTxt}
            />
            <ClubsGallery data={gallery} isBlackBg={isBlackBg}/>
        </section>
    )
}

export default Clubs
