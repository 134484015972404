import React from 'react'
import ClubCard from './ClubCard'

const ClubsGallery = ({data}) => {
    return (
        <div className="clubs___gridwrapper">
            {
                data?.map(({node: club} , index)=> {
                    return (
                        <ClubCard 
                            key={index}
                            title={club.title}
                            // image={club.coverimage ? club.coverimage : false}
                            slug={club.slug}
                            videourl={club.videourl}
                        />
                    )
                })

            }
        </div>
    )
}

export default ClubsGallery
