import React from "react"
import { Link } from "gatsby"
import ButtonLinkWrapper from "../../../ui/btnLinkWrapper/ButtonLinkWrapper"
import MyButton from "../../../ui/button/MyButton"
import Arrow from "../../../ui/btnArrow/Arrow"
import ReactPlayer from 'react-player/lazy'
import { StaticImage } from "gatsby-plugin-image"

const ClubCard = ({ title, slug, image ,videourl }) => {
  return (
    <div className="club__card">
      <div className="club__card__wrapper">
        <div className="club__top">
          {
            videourl ?
            <ReactPlayer url={videourl} loop={true} width="100%" />
            : 
            <div style={{background: `black`, padding: `8px`}} >
              <StaticImage src="../../../images/olympwhite.png" alt="join us"   layout="fullWidth"/>
            </div>
          }
          <h3 className="club__title">{title}</h3>
        </div>

        <Link to={`/clubs/${slug}`} style={{ textDecoration: "none" }}>
          <ButtonLinkWrapper>
            <MyButton isBlackBg={false}>ДЕТАЛЬНІШЕ</MyButton>
            <Arrow isBlackBg={false} />
          </ButtonLinkWrapper>
        </Link>
      </div>
    </div>
  )
}

export default ClubCard
