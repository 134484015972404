import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import BreadCrumps from "../ui/breadCrumps/BreadCrumps";
import Clubs from "../components/sections/ourClasses/Clubs";
import { HelmetDatoCms } from "gatsby-source-datocms";

const ClubsPage = ({ data }) => (
  <>
    <HelmetDatoCms seo={data.page.seo} favicon={data.site.favicon} />
    <Layout>
      <BreadCrumps title={"НАШІ КЛУБИ"} />
      <Clubs
        sectionTitle={data.page.sectiontitle}
        sectionTitleDesc={data.page.sectiondescription}
        hideBtn={true}
        gallery={data.allclubs.edges ? data.allclubs.edges : null}
      />
    </Layout>
  </>
);

export default ClubsPage;

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    page: datoCmsClubspage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      sectiontitle
      sectiondescription
    }
    allclubs: allDatoCmsClub(sort: { order: DESC, fields: title }) {
      edges {
        node {
          slug
          title
          videourl
        }
      }
    }
  }
`;
